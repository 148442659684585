var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          {
            attrs: { title: "Card Actions", "action-buttons": "" },
            on: { refresh: _vm.closeCardAnimationDemo },
          },
          [
            _c(
              "table",
              {
                staticClass: "border-collapse",
                staticStyle: { width: "100%" },
              },
              [
                _c("tr", [
                  _c(
                    "th",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Action")]
                  ),
                  _c(
                    "th",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light text-center",
                    },
                    [_vm._v("Icon")]
                  ),
                  _c(
                    "th",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Detail")]
                  ),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Collapse")]
                  ),
                  _c(
                    "td",
                    {
                      staticClass:
                        "border border-solid d-theme-border-grey-light text-center",
                    },
                    [
                      _c("feather-icon", {
                        attrs: {
                          icon: "ChevronDownIcon",
                          svgClasses: "h-5 w-5",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Collapse card content using collapse action.")]
                  ),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Refresh Content")]
                  ),
                  _c(
                    "td",
                    {
                      staticClass:
                        "border border-solid d-theme-border-grey-light text-center",
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "RotateCwIcon", svgClasses: "h-4 w-4" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Refresh your card content using refresh action.")]
                  ),
                ]),
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Remove Card")]
                  ),
                  _c(
                    "td",
                    {
                      staticClass:
                        "border border-solid d-theme-border-grey-light text-center",
                    },
                    [
                      _c("feather-icon", {
                        attrs: { icon: "XIcon", svgClasses: "h-5 w-5" },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "td",
                    {
                      staticClass:
                        "p-2 border border-solid d-theme-border-grey-light",
                    },
                    [_vm._v("Remove card from page using remove card action")]
                  ),
                ]),
              ]
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Collapsable Card", "collapse-action": "" } },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can create collapsible content card by setting "),
              _c("code", [_vm._v("collapse-action")]),
              _vm._v(" prop to "),
              _c("code", [_vm._v("true")]),
              _vm._v(". By default it is set to false."),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can also get this action if you set "),
              _c("code", [_vm._v("action-buttons")]),
              _vm._v(" prop to "),
              _c("code", [_vm._v("true")]),
              _vm._v("."),
            ]),
            _c(
              "p",
              [
                _vm._v("Click on "),
                _c("feather-icon", {
                  staticClass: "align-middle",
                  attrs: { icon: "ChevronDownIcon", svgClasses: "h-5 w-5" },
                }),
                _vm._v(" icon to see card collapse in action."),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base" },
      [
        _c(
          "vx-card",
          { attrs: { title: "Remove Card", "remove-card-action": "" } },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can add remove card action by setting "),
              _c("code", [_vm._v("remove-card-action")]),
              _vm._v(" prop to "),
              _c("code", [_vm._v("true")]),
              _vm._v(". By default it is set to false."),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can also get this action if you set "),
              _c("code", [_vm._v("action-buttons")]),
              _vm._v(" prop to "),
              _c("code", [_vm._v("true")]),
              _vm._v("."),
            ]),
            _c(
              "p",
              [
                _vm._v("Click on "),
                _c("feather-icon", {
                  staticClass: "align-middle",
                  attrs: { icon: "XIcon", svgClasses: "h-4 w-4" },
                }),
                _vm._v(" icon to see close card in action."),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 mb-base" },
      [
        _c(
          "vx-card",
          {
            attrs: { title: "Refresh Content", "refresh-content-action": "" },
            on: { refresh: _vm.closeCardAnimationDemo },
          },
          [
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can add refresh content action by setting "),
              _c("code", [_vm._v("refresh-content-action")]),
              _vm._v(" prop to "),
              _c("code", [_vm._v("true")]),
              _vm._v(". By default it is set to false."),
            ]),
            _c("p", { staticClass: "mb-3" }, [
              _vm._v("You can also get this action if you set "),
              _c("code", [_vm._v("action-buttons")]),
              _vm._v(" prop to "),
              _c("code", [_vm._v("true")]),
              _vm._v("."),
            ]),
            _c(
              "p",
              [
                _vm._v("Click on "),
                _c("feather-icon", {
                  staticClass: "align-middle",
                  attrs: { icon: "RotateCwIcon", svgClasses: "h-4 w-4" },
                }),
                _vm._v(" icon to see refresh card content in action."),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "vx-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 md:mt-0" },
      [
        _c(
          "vx-card",
          {
            attrs: { title: "Action Buttons", "action-buttons": "" },
            on: { refresh: _vm.closeCardAnimationDemo },
          },
          [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v("You can add all three action by setting "),
              _c("code", [_vm._v("action-buttons")]),
              _vm._v(" prop to "),
              _c("code", [_vm._v("true")]),
              _vm._v(". By default it is set to false."),
            ]),
            _c(
              "p",
              { staticClass: "mb-1" },
              [
                _vm._v("Click on "),
                _c("feather-icon", {
                  staticClass: "align-middle",
                  attrs: { icon: "ChevronDownIcon", svgClasses: "h-5 w-5" },
                }),
                _vm._v(" icon to see card collapse in action."),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mb-1" },
              [
                _vm._v("Click on "),
                _c("feather-icon", {
                  staticClass: "align-middle",
                  attrs: { icon: "RotateCwIcon", svgClasses: "h-4 w-4" },
                }),
                _vm._v(" icon to see refresh card content in action."),
              ],
              1
            ),
            _c(
              "p",
              { staticClass: "mb-1" },
              [
                _vm._v("Click on "),
                _c("feather-icon", {
                  staticClass: "align-middle",
                  attrs: { icon: "XIcon", svgClasses: "h-4 w-4" },
                }),
                _vm._v(" icon to see close card in action."),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }